import React from "react";
import emergence from "emergence.js";

import { siteMetadata } from "../../../gatsby-config";

import "modern-normalize/modern-normalize.css";
import "prismjs/themes/prism.css";
import "scss/gatstrap.scss";
import "animate.css/animate.css";
import "font-awesome/css/font-awesome.css";

class Layout extends React.Component {
  componentDidMount() {
    emergence.init();
  }

  componentDidUpdate() {
    emergence.init();
  }
  render() {
    const { children } = this.props;

    const mauticScript = `
    (function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
    m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://mautic.notnow.co/mtc.js','mt');

    mt('send', 'pageview');`;

    return (
      <>
        {children}

        <script dangerouslySetInnerHTML={{ __html: mauticScript }} />
        <script
          src="//mautic.notnow.co/focus/2.js"
          type="text/javascript"
          charset="utf-8"
          async
        />
      </>
    );
  }
}

export default Layout;
